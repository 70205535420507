import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DatePickerEnum, DatePickerType, NgxDateRangePickerOption, NgxDateRangePickerOutput } from 'ngx-date-range-picker';
import { ExportReportOutput, MinuteFrequencyOption } from '../../../shared/models';

@Component({
  selector: 'dr-customer-offers-ui-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportReportComponent {
  protected error = '';
  protected invalidFormName: string | undefined;
  private minuteFrequency: MinuteFrequencyOption = 30;

  protected startHour12 = 12;
  protected startPeriod = 'am';
  protected startMinute = 0;

  protected endHour12 = 12;
  protected endPeriod = 'am'
  protected endMinute = 0;

  // Selected date range (for the mat-datepicker)
  protected startDate: Date | undefined;
  protected endDate: Date | undefined;

  // Selected date range (for the mat-calendar)
  protected selecteDatedRange!: DateRange<Date | null>;

  // Min and max dates
  protected minStartDate!: Date | undefined;
  protected minEndDate!: Date | undefined;
  protected maxStartDate!: Date | undefined;
  protected maxEndDate!: Date | undefined;

  options: NgxDateRangePickerOption[] = [];
  date: Date | undefined;
  protected selectedType: DatePickerType = DatePickerEnum.DatePicker;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { minuteFrequency: number, minDate: moment.Moment, maxDate: moment.Moment}, public dialogRef: MatDialogRef<ExportReportComponent>, private translate: TranslateService) {
    // To limit the date range selection, pass a maxDate and a minDate.
    // The user can choose a range between any dates if there are no minDate and maxDate.
    this.initData(data);
  }

  private initData(data: { minuteFrequency: number, minDate: moment.Moment, maxDate: moment.Moment }): void {
    // Check if the provided value is type MinuteFrecuencyOption
    if (this.isMinuteFrecuencyOption(data.minuteFrequency)) {
      this.minuteFrequency = data.minuteFrequency as MinuteFrequencyOption;
    } else {
      // If not, set default and throw an warning
      console.warn('The minuteFrequency should be one of the following numbers: 0, 5, 10, 15, 30. The default of 30 mins as frequency will be used.')
      this.minuteFrequency = 30;
    }

    // To limit the date range selection, pass a maxDate and a minDate.
    // The user can choose a range between any dates if there are no minDate and maxDate.
    if (data && moment.isMoment(data.minDate) && moment.isMoment(data.maxDate)) {
      this.minStartDate = data.minDate.toDate();
      this.minEndDate = data.minDate.toDate();
      this.maxStartDate = data.maxDate.toDate();
      this.maxEndDate = data.maxDate.toDate();
    }
  }

  protected incrementHour(type: string): void {
    // Increment the hours by 1 and update the converted time
    if (type === 'start') {
      if (this.startHour12 < 12) {
        this.startHour12++;
      } else {
        this.startHour12 = 1;
      }
    } else if (type === 'end') {
      if (this.endHour12 < 12) {
        this.endHour12++;
      } else {
        this.endHour12 = 1;
      }
    }
  }

  protected decrementHour(type: string): void {
    // Decrement the hours by 1 and update the converted time
    if (type === 'start') {
      if (this.startHour12 > 1) {
        this.startHour12--;
      } else {
        this.startHour12 = 12;
      }
    } else if (type === 'end') {
      if (this.endHour12 > 1) {
        this.endHour12--;
      } else {
        this.endHour12 = 12;
      }
    }
  }

  protected incrementMinuteByFrequency(type: string): void {
    const maxMinute = 59;
    let newMinute = 0;

    if (type === 'start') {
      newMinute = this.startMinute + this.minuteFrequency;

      if (newMinute <= maxMinute) {
        this.startMinute = newMinute;
      } else {
        // Handle the case where the new minute exceeds 59
        this.startMinute = 0;
      }
    } else if (type === 'end') {
      newMinute = this.endMinute + this.minuteFrequency;

      if (newMinute <= maxMinute) {
        this.endMinute = newMinute;
      } else {
        // Handle the case where the new minute exceeds 59
        this.endMinute = 0;
      }
    }
  }

  protected decrementMinuteByFrequency(type: string): void {
    const minMinute = 0;
    let newMinute = 0;

    if (type === 'start') {
      newMinute = this.startMinute - this.minuteFrequency;
      if (newMinute >= minMinute) {
        this.startMinute = newMinute;
      } else {
        // Handle the case where the new minute goes below 0
        this.startMinute = 60 - this.minuteFrequency;
      }
    } else if (type === 'end') {
      newMinute = this.endMinute - this.minuteFrequency;
      if (newMinute >= minMinute) {
        this.endMinute = newMinute;
      } else {
        // Handle the case where the new minute goes below 0
        this.endMinute = 60 - this.minuteFrequency;
      }
    }
  }

  private isMinuteFrecuencyOption(myVar: number): myVar is MinuteFrequencyOption {
    return myVar === 0 || myVar === 5 || myVar === 10 || myVar === 15 || myVar === 30;
  }

  protected selectedDateRangeChange(date: Date): void {
    if (
      this.selecteDatedRange &&
      this.selecteDatedRange.start &&
      date >= this.selecteDatedRange.start &&
      !this.selecteDatedRange.end
    ) {
      this.endDate = date;
      this.maxStartDate = date;
      this.selecteDatedRange = new DateRange(
        this.selecteDatedRange.start,
        date
      );
    } else if (
      this.selecteDatedRange &&
      this.selecteDatedRange.end &&
      date <= this.selecteDatedRange.end &&
      !this.selecteDatedRange.start
    ) {
      this.startDate = date;
      this.minEndDate = date;
      this.selecteDatedRange = new DateRange(
        date,
        this.selecteDatedRange.end,
      );
    } else {
      this.startDate = date;
      this.endDate = undefined;
      this.minEndDate = date;
      this.selecteDatedRange = new DateRange(date, null);
    }
  }

  protected endDateChange(d: NgxDateRangePickerOutput | Date): void {
    const date = d as Date;
    this.endDate = date as Date;
    this.maxStartDate = date;
    if (date && this.startDate) {
      if (date >= this.startDate) {
        this.selecteDatedRange = new DateRange(
          this.startDate,
          date
        );
      } else if (this.selecteDatedRange && this.selecteDatedRange.end) {
        this.endDate = new Date(this.selecteDatedRange.end);
      }
    } else if (date && !this.startDate) {
      this.selecteDatedRange = new DateRange(
        null,
        date
      );
    }
  }

  protected startDateChange(d: NgxDateRangePickerOutput | Date): void {
    const date = d as Date;
    this.startDate = date;
    this.minEndDate = date;
    if (date && this.endDate) {
      if (date <= this.endDate) {
        this.selecteDatedRange = new DateRange(
          date,
          this.endDate,
        );
      } else if (this.selecteDatedRange && this.selecteDatedRange.start) {
        this.startDate = new Date(this.selecteDatedRange.start);
      }
    } else if (date && !this.endDate) {
      this.selecteDatedRange = new DateRange(
        date,
        null
      );
    }
  }

  protected clearStartDate(): void{
    this.startDate = undefined;
    this.minEndDate = undefined;
    if(this.endDate){
      this.selecteDatedRange = new DateRange(
        null,
        this.endDate,
      );
    }else{
      this.selecteDatedRange = new DateRange(null, null);
    }
  }

  protected clearEndDate(): void{
    this.endDate = undefined;
    this.maxStartDate = undefined;
    if(this.startDate){
      this.selecteDatedRange = new DateRange(
        this.startDate,
        null
      );
    }else{
      this.selecteDatedRange = new DateRange(null, null);
    }
  }

  private checkIfDataIsValid(): boolean {
    if (!this.startDate || !this.endDate) {
      this.invalidFormName = 'startEndDate';
      this.error = this.translate.instant('COM.ERRORS.EXPORT_REPORT.EMPTY_DATES');
      return false;
    } else if (moment(this.endDate).isBefore(moment(this.startDate))) {
      this.invalidFormName = 'startEndDate';
      this.error = this.translate.instant('COM.ERRORS.EXPORT_REPORT.END_DATE');
      return false;
    } else if (!this.endDateTimeIsAfterStartDateTime()) {
      this.invalidFormName = 'startEndTime';
      this.error = this.translate.instant('COM.ERRORS.EXPORT_REPORT.END_TIME');
      return false;
    } else {
      this.error = this.translate.instant('COM.ERRORS.GENERIC_ERROR');
      this.invalidFormName = undefined;
      return true;
    }
  }

  private endDateTimeIsAfterStartDateTime(): boolean {
    // Validate values
    if (!this.endDate || !this.startDate) {
      return false;
    }

    const start = this.createMomentWithDateAndTime(this.startDate, this.startHour12, this.startMinute, this.startPeriod);
    const end = this.createMomentWithDateAndTime(this.endDate, this.endHour12, this.endMinute, this.endPeriod);

    return (end && start) ? end.isAfter(start) : false;
  }

  private createMomentWithDateAndTime(
    date: Date,
    hour12: number,
    minutes: number,
    period: string
  ): moment.Moment | null {
    // Validate input values
    if (
      isNaN(date.getTime()) || // Check if the date is valid
      hour12 < 1 || hour12 > 12 ||   // Check if the hour is in the valid 1-12 range
      minutes < 0 || minutes > 59 || // Check if minutes are in the valid 0-59 range
      (period !== 'am' && period !== 'pm') // Check if period is 'AM' or 'PM'
    ) {
      console.log('Invalid input values.');
      return null;
    }

    // Extract the day, month, and year from the input date
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    // Convert 12-hour format to 24-hour format
    let hour24 = hour12;
    if (period === 'pm' && hour12 !== 12) {
      hour24 += 12;
    } else if (period === 'am' && hour12 === 12) {
      hour24 = 0;
    }

    // Create a Moment.js object
    return moment({
      year: year,
      month: month,
      day: day,
      hour: hour24,
      minute: minutes,
      second: 0, // Set seconds to 0 if not needed
    });
  }

  protected apply(): void {
    if (this.checkIfDataIsValid()) {
      const output: ExportReportOutput = {
        startMomentDate: this.startDate ? this.createMomentWithDateAndTime(this.startDate, this.startHour12, this.startMinute, this.startPeriod) : undefined,
        endMomentDate: this.endDate ? this.createMomentWithDateAndTime(this.endDate, this.endHour12, this.endMinute, this.endPeriod) : undefined
      }
      this.dialogRef.close(output)
    }
  }

  protected removeStartDate(): void {
    this.startDate = undefined;
    this.minEndDate = this.minStartDate;
    if (this.endDate) {
      this.selecteDatedRange = new DateRange(
        null,
        this.endDate,
      );
    } else {
      this.selecteDatedRange = new DateRange<Date>(null, null);
    }
  }

  protected removeEndDate(): void {
    this.endDate = undefined;
    this.maxStartDate = this.maxEndDate;
    if (this.startDate) {
      this.selecteDatedRange = new DateRange(
        this.startDate,
        null,
      );
    } else {
      this.selecteDatedRange = new DateRange<Date>(null, null);
    }
  }
}
