import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegistrationViewConfigResponse } from '@dr-customer-offers-ui/lib-interfaces';
import { APIData } from 'common-utils/dist/models/api';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiEndpointsService } from '../api-endpoint/api-endpoints.service';

@Injectable({ providedIn: 'root' })
export class RegistrationViewConfigService {
  constructor(
    private http: HttpClient,
    private apiService: ApiEndpointsService,
  ) {}

  getRegistrationViewConfig(registrationId: string): Observable<RegistrationViewConfigResponse> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/registrations/` + registrationId + `/registration-view-config`;
    return this.http.get<APIData<RegistrationViewConfigResponse>>(url, {withCredentials: true}).pipe(
      map((res: APIData<RegistrationViewConfigResponse>) => res.data),
      catchError(this.handleError),
    );
  }


  private handleError(error: Error | HttpErrorResponse) {
    return throwError(() => error);
  }
}
