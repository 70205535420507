import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalAlertService } from './global-alert.service';

@Injectable({ providedIn: 'root' })
export class MessageService {

    constructor(private alertService: GlobalAlertService){}

    handleError(err: HttpErrorResponse){
        const requestUrl = err.url;
        const statusCode = err.status;
        let errorMessage = '';

        if(err.error instanceof ErrorEvent) {
            // A client-side or network ocurred. Handle it accordingly.
            errorMessage =  `An error ocurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response.
            // Customize errors based on the error code and url.
            if(statusCode === 0){
                errorMessage =  `Oops! Something went wrong. Please try again later.`
            } else if(statusCode === 403){
                errorMessage =  `You don't have permission to access the requested resource.`
            } else {
                if(requestUrl?.includes('v1/registrations')){
                    errorMessage = 'Failed to load registrations.'
                }
                if(requestUrl?.includes('registration-view-config')){
                    errorMessage = 'You do not have Authorization to view offers for this Registration.'
                }
            }
        }

        if(errorMessage){
            this.alertService.setError(errorMessage);
        }
    }

    handleSuccess(message: string) {
        this.alertService.setSuccess(message);
    }
}