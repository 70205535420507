<div class="reason-code-confirm__top">
    <mat-icon class="reason-code-confirm__top-icon">edit</mat-icon>
    <h4 class="reason-code-confirm__top-title">{{'COM.DIALOGS.REASON_CODE.SUBTITLE' | translate}}</h4>
    <div class="reason-code-confirm__top-msg">
        <span>{{'COM.DIALOGS.REASON_CODE.MSG' | translate}}</span>
    </div>
</div>
<mat-dialog-content class="mat-typography pt-5 pb-2 auto-height">
    <div class="container">
        <div class="row mb-2">
            <mat-form-field>
                <mat-label>{{'COM.DIALOGS.REASON_CODE.SELECT_REASON' | translate}}</mat-label>
                <mat-select [(value)]="selectedReason">
                  <mat-option value="{{reason}}" *ngFor="let reason of postGateChangeReasons">{{reason}}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="pb-4 pe-5 ps-5 pt-5 justify-content-end">
    <button mat-flat-button (click)="cancel()" color="secondary" class="reason-code-confirm__btn">{{'COM.BUTTONS.CANCEL' | translate}}</button>
    <button mat-flat-button (click)="apply()" color="primary" [disabled]="!selectedReason" class="reason-code-confirm__btn">{{'COM.BUTTONS.APPLY' | translate}}</button>
</mat-dialog-actions>
