import * as moment from "moment-timezone";
import { NgxDateRangePickerOutput } from "ngx-date-range-picker";
import { DataModel, TableDataType } from "ngx-interval-data-grid";
import { MinuteFrequencyOption } from "./tab.model";

export type UIState = ''|'primary'|'gate-closed'|'emergency-gate'|'opt-out'|'holidays'|'daylight-savings'|'forcasted-cleared';

export const UIState = {
  Default: '' as UIState,
  Primary: 'primary' as UIState,
  GateClosed: 'gate-closed' as UIState,
  EmergencyGate: 'emergency-gate' as UIState,
  OptOut: 'opt-out' as UIState,
  Holidays: 'holidays' as UIState,
  DaylightSavings: 'daylight-savings' as UIState,
  ForcastedCleared: 'forcasted-cleared' as UIState
}

export const DefaultState = UIState.Default;

export interface FilterSelectionInterface {
  programId: string;
  registrationId: string;
  dateRange: NgxDateRangePickerOutput
}

export interface DayOfWeekModel {
  data: DataModel[],
  timeZone: string,
  startDate: moment.Moment,
  endDate: moment.Moment,
  intervalFrequency: MinuteFrequencyOption,
  tableType: TableDataType
}
