import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { MessageService } from '../services/message.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  
  constructor(private loaderService: LoaderService,private messageService: MessageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.showLoader();
    return next.handle(req).pipe(
      finalize(() => {
        this.loaderService.hideLoader()
      }),
      catchError((error: HttpErrorResponse) => {
        this.messageService.handleError(error);
        return throwError(() => error);
      })
    );
  }
}