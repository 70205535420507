<h3 class="red" *ngIf="error">{{error}}</h3>
<div class="d-flex align-items-center">
    <div class="small-height mt-3 me-3">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-20">
            <input
            [(ngModel)]="bulkValue"
            validateNumber
            [min]="minBulkValue"
            [max]="maxBulkValue"
            type="number"
            class="mt-1 input-number-no-arrows"
            matInput placeholder="{{'COM.DIALOGS.BULK.ENTER_VALUE' | translate}}" />
        </mat-form-field>
    </div>
    <div class="small-height mt-3 me-3">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-20">
            <mat-select [(value)]="selectedPeriod" (valueChange)="optionChange(selectedPeriod)">
                <mat-option [value]="option.value" *ngFor="let option of periodOptions">{{ option.label
                    }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="ps-3 mt-3 bulk-offers-border">
        <button (click)="apply()"
        [disabled]="(bulkValue === null || bulkValue === undefined || ((bulkValue < minBulkValue || bulkValue > maxBulkValue) && selectedTabOption.name !== 'Baselines'))
        || (!regConfig?.gateRules?.post_gate_edits?.allow_offer_edit && selectedTabOption.value === TabOptionsEnum.Open)"
        mat-flat-button
        color="primary"
        class="round-button apply-btn">{{'COM.BUTTONS.APPLY' | translate}}</button>
    </div>
</div>
