import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaselineRequest, BaselineResponse } from '@dr-customer-offers-ui/lib-interfaces';
import { APIData } from 'common-utils/dist/models/api';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiEndpointsService } from '../api-endpoint/api-endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class BaselineService {

  constructor(
    private http: HttpClient,
    private apiService: ApiEndpointsService,
  ) { }

  getBaselines(registration_id: string, startDate: string, endDate: string): Observable<BaselineResponse[]> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/registrations/` + registration_id + `/baselines?start_dttm=${startDate}&end_dttm=${endDate}`;
    return this.http.get<APIData<BaselineResponse[]>>(url, {withCredentials: true}).pipe(
      map((res) => this.extractBaselinesData(res)),
      catchError(this.handleError),
    );
  }

  putBaselines(registration_id: string, offers: BaselineRequest[]): Observable<BaselineResponse[]> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/registrations/` + registration_id + `/baselines`;
    return this.http.put<APIData<BaselineResponse[]>>(url, offers, {withCredentials: true}).pipe(
        map((res) => this.extractBaselinesData(res))
      );
  }

  private extractBaselinesData(res: APIData<BaselineResponse[]>): BaselineResponse[] {
    const payload = res.data;
    return payload as BaselineResponse[];
  }

  private handleError(error: Error | HttpErrorResponse) {
    return throwError(() => error);
  }
}
