import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalAlertService } from '../../shared/services/global-alert.service';

@Component({
  selector: 'dr-customer-offers-ui-global-alert',
  templateUrl: './global-alert.component.html',
  styleUrls: ['./global-alert.component.scss'],
})
export class GlobalAlertComponent implements OnInit, OnDestroy {
  /* tslint:disable:no-unused-variable */
  private subs = new Subscription();
  isErrorAlertVisible!: boolean;
  public message: any;

  constructor(private service: GlobalAlertService) {}

  ngOnInit(): void {
    this.isErrorAlertVisible = false;
    this.subs.add(
      this.service.messages.subscribe(message => {
        this.displayAlert(message);
      })
    )
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

  private displayAlert(message: string): void {
    this.message = message;
    this.isErrorAlertVisible = true;
    setTimeout(() => {
      this.isErrorAlertVisible = false;
    }, this.message.timeout);
  }

  get isError(): boolean {
    return this.message.type === 'error';
  }

  get isSuccess(): boolean {
    return this.message.type === 'success';
  }

  get messageClassName() {
    return {
      'global-message': true,
      alert: true,
      'alert-danger': this.isError,
      'alert-success': this.isSuccess,
    };
  }

  get iconClassName() {
    return {
      icon: true,
      fa: true,
      'fa-ban': this.isError,
      'fa-check-circle': this.isSuccess,
    };
  }
}