import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dr-customer-offers-ui-lib-sample',
  template: `
    <div>
      This is a sample component from the library!
    </div>
  `,
  styles: []
})
export class SampleComponent implements OnInit {
  constructor() {
    console.log('SampleComponent works - constructor');
  }

  ngOnInit(): void {
    console.log('SampleComponent works - ngOnInit');
  }
}