import { Pipe, PipeTransform } from "@angular/core";
import { DefaultIconSize, IconSize } from "./icons-size.enum";

@Pipe({
  standalone: true,
  name: 'iconSize',
  pure: true
})
export class IconSizePipe implements PipeTransform {
  transform(value: IconSize): string {
    return this.getIconSize(value);
  }

  private getIconSize(size?: IconSize): string {
    size = size || DefaultIconSize;
    switch(size) {
      case IconSize.ExtraSmall:
        return 'com-icon--extra-small';

      case IconSize.Small:
        return 'com-icon--small';

      case IconSize.Medium:
        return 'com-icon--medium';

      case IconSize.Large:
        return 'com-icon--large';

      default:
        return '';
    }
  }
}
