import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { GlobalAlertComponent } from './global-alert.component';


@NgModule({
  declarations: [GlobalAlertComponent],
  exports: [GlobalAlertComponent],
  imports: [BrowserModule],
  bootstrap: [GlobalAlertComponent],
})
export class GlobalAlertModule {}
