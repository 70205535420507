import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProgramResponse } from '@dr-customer-offers-ui/lib-interfaces';
import { APIData } from 'common-utils/dist/models/api';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiEndpointsService } from '../api-endpoint/api-endpoints.service';

@Injectable({ providedIn: 'root' })
export class ProgramService {
  constructor(
    private http: HttpClient,
    private apiService: ApiEndpointsService,
  ) {}
  
  getPrograms(): Observable<ProgramResponse[]> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/programs?application=ATLAS`;
    return this.http.get<APIData<ProgramResponse[]>>(url, {withCredentials: true}).pipe(
      map((res) => this.extractProgramsData(res)),
      catchError(this.handleError),
    );
  }

  private extractProgramsData(res: APIData<ProgramResponse[]>): ProgramResponse[] {
    const payload = res.data;
    return payload as ProgramResponse[];
  }

  private handleError(error: Error | HttpErrorResponse) {
    return throwError(() => error);
  }
}
