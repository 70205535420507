<div>
    <h2 mat-dialog-title class="export-report__title">{{'COM.LABELS.EXPORT_REPORT' | translate}}</h2>
    <button class="export-report__close-btn" mat-icon-button [mat-dialog-close]="null" aria-label="Dialog close">
        <dr-customer-offers-ui-icons class="circle-icon" name="close" size="small" state="primary"></dr-customer-offers-ui-icons>
    </button>
</div>
<mat-dialog-content class="mat-typography pt-5 pb-2 auto-height">
    <div class="container">
        <div class="calendar-height row mb-2">
            <div class="col-6">
                <div class="row">
                    <div class="col-12 mb-3">
                        <h5 class="content-title content-title--date">{{'COM.LABELS.START_DATE' | translate}}</h5>
                    </div>
                </div>
                <div class="row small-height mb-5">
                    <!-- ---------- -->
                    <!-- START DATE -->
                    <!-- ---------- -->
                    <lib-ngx-date-range-picker
                       ngDefaultControl
                        [datePickerType]="selectedType"
                        [title]="'From'"
                        [border]="true"
                        [options]="options"
                        [date]="startDate"
                        [minDate]="minStartDate"
                        [maxDate]="maxStartDate"
                        [disabled]="false"
                        [invalid]="false"
                        [hint]="'MM/DD/YYYY'"
                        class="export-date"
                        [(ngModel)]="startDate"
                        (onClear)="clearStartDate()"
                        (onChange)="startDateChange($event)"
                        >
                    </lib-ngx-date-range-picker>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <h5 class="content-title content-title--date">{{'COM.LABELS.END_DATE' | translate}}</h5>
                    </div>
                </div>
                <div class="row small-height mb-5">
                    <!-- ---------- -->
                    <!-- END DATE -->
                    <!-- ---------- -->
                    <lib-ngx-date-range-picker
                        ngDefaultControl
                        [datePickerType]="selectedType"
                        [title]="'To'"
                        [border]="true"
                        [options]="options"
                        [date]="endDate"
                        [minDate]="minEndDate"
                        [maxDate]="maxEndDate"
                        [disabled]="false"
                        [invalid]="false"
                        [hint]="'MM/DD/YYYY'"
                        class="export-date"
                        [(ngModel)]="endDate"
                        (onClear)="clearEndDate()"
                        (onChange)="endDateChange($event)"
                        >
                    </lib-ngx-date-range-picker>
                </div>
            </div>
            <div class="col-6">
                <h5 class="content-title content-title--date pb-2">{{'COM.LABELS.SELECT_DATES' | translate}}</h5>
                <mat-calendar class="no-header-controls" (selectedChange)="selectedDateRangeChange($event)"
                    [minDate]="minStartDate" [maxDate]="maxEndDate" [selected]="selecteDatedRange"></mat-calendar>
            </div>
        </div>
        <div class="export">
          <div class="export_container">
            <div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <h1 class="content-title">{{'COM.LABELS.START_TIME' | translate}}</h1>
                    </div>
                </div>
                <div class="time">
                    <!-- --------------- -->
                    <!-- START TIME HOUR -->
                    <!-- --------------- -->
                    <div class="time-container">
                        <button (click)="incrementHour('start')" mat-icon-button aria-label="Increase hour"
                            class="time-container_increament">
                            <dr-customer-offers-ui-icons name="expand_less" size="extra-small"></dr-customer-offers-ui-icons>
                        </button>
                        <div class="time-container_textbox small-height">
                            <mat-form-field class="d-flex" appearance="outline" subscriptSizing="dynamic"
                                [ngClass]="{'mat-form-field-invalid': invalidFormName === 'startEndTime'}"
                                name="formStartHour">
                                <input [ngModel]="startHour12 | twoDigitFormat" readonly class="time-container_textbox--input" matInput name="startHour"
                                    placeholder="Hour" />
                            </mat-form-field>
                        </div>

                        <button (click)="decrementHour('start')" mat-icon-button aria-label="Decrease hour"
                            class="time-container_decreament">
                            <dr-customer-offers-ui-icons name="expand_more" size="extra-small"></dr-customer-offers-ui-icons>
                        </button>
                    </div>
                    <span class="content-colon">:</span>
                    <!-- ----------------- -->
                    <!-- START TIME MINUTE -->
                    <!-- ----------------- -->
                    <div class="time-container">
                        <button (click)="incrementMinuteByFrequency('start')" mat-icon-button
                            aria-label="Increase minute" class="time-container_increament">
                            <dr-customer-offers-ui-icons name="expand_less" size="extra-small"></dr-customer-offers-ui-icons>
                        </button>
                        <div class="time-container_textbox small-height">
                            <mat-form-field class="d-flex" appearance="outline" subscriptSizing="dynamic"
                                [ngClass]="{'mat-form-field-invalid': invalidFormName === 'startEndTime'}"
                                name="formStartMinute">
                                <input [ngModel]="startMinute | twoDigitFormat" readonly class="time-container_textbox--input" matInput placeholder="21"
                                    name="startMinute" />
                            </mat-form-field>
                        </div>

                        <button (click)="decrementMinuteByFrequency('start')" mat-icon-button
                            aria-label="Decrease minute" class="time-container_decreament">
                            <dr-customer-offers-ui-icons name="expand_more" size="extra-small"></dr-customer-offers-ui-icons>
                        </button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="small-height mat-button-container--toggle mat-button-container--toggle-border">
                        <mat-button-toggle-group [(ngModel)]="startPeriod" name="startPeriod"
                            [ngClass]="{'mat-button-toggle-invalid': invalidFormName === 'startEndTime'}"
                            aria-label="Time Format Toggle">
                            <mat-button-toggle value="am">{{'COM.LABELS.AM' | translate}}</mat-button-toggle>
                            <mat-button-toggle value="pm">{{'COM.LABELS.PM' | translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </div>
            <div class="export_container--border"></div>
            <div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <h1 class="content-title">{{'COM.LABELS.END_TIME' | translate}}</h1>
                    </div>
                </div>
                <div class="time">
                    <!-- ------------- -->
                    <!-- END TIME HOUR -->
                    <!-- ------------- -->
                    <div class="time-container">
                        <button (click)="incrementHour('end')" mat-icon-button aria-label="Increase hour"
                            class="time-container_increament">
                            <dr-customer-offers-ui-icons name="expand_less" size="extra-small"></dr-customer-offers-ui-icons>
                        </button>

                        <div class="time-container_textbox small-height">
                            <mat-form-field class="d-flex" appearance="outline" subscriptSizing="dynamic"
                                [ngClass]="{'mat-form-field-invalid': invalidFormName === 'startEndTime'}"
                                name="formEndHour">
                                <input [ngModel]="endHour12 | twoDigitFormat" readonly class="time-container_textbox--input" matInput name="endHour"
                                    placeholder="Hour" />
                            </mat-form-field>
                        </div>

                        <button (click)="decrementHour('end')" mat-icon-button aria-label="Decrease hour"
                            class="time-container_decreament">
                            <dr-customer-offers-ui-icons name="expand_more" size="extra-small"></dr-customer-offers-ui-icons>
                        </button>
                    </div>
                    <span class="content-colon">:</span>
                    <!-- --------------- -->
                    <!-- END TIME MINUTE -->
                    <!-- --------------- -->
                    <div class="time-container">

                        <button (click)="incrementMinuteByFrequency('end')" mat-icon-button
                            aria-label="Increase minute" class="time-container_increament">
                            <dr-customer-offers-ui-icons name="expand_less" size="extra-small"></dr-customer-offers-ui-icons>
                        </button>

                        <div class="time-container_textbox small-height">
                            <mat-form-field class="d-flex" appearance="outline" name="formEndMinute"
                                [ngClass]="{'mat-form-field-invalid': invalidFormName === 'startEndTime'}"
                                subscriptSizing="dynamic">
                                <input [ngModel]="endMinute | twoDigitFormat" readonly class="time-container_textbox--input" matInput placeholder="Minute"
                                    name="endMinute" />
                            </mat-form-field>
                        </div>

                        <button (click)="decrementMinuteByFrequency('end')" mat-icon-button
                            aria-label="Decrease minute" class="time-container_decreament">
                            <dr-customer-offers-ui-icons name="expand_more" size="extra-small"></dr-customer-offers-ui-icons>
                        </button>

                    </div>
                </div>
                <div class="row mt-3">
                    <div class="small-height mat-button-container--toggle mat-button-container--toggle-border">
                        <mat-button-toggle-group [(ngModel)]="endPeriod" name="endPeriod"
                            [ngClass]="{'mat-button-toggle-invalid': invalidFormName === 'startEndTime'}"
                            aria-label="Time Format Toggle">
                            <mat-button-toggle value="am">{{'COM.LABELS.AM' | translate}}</mat-button-toggle>
                            <mat-button-toggle value="pm">{{'COM.LABELS.PM' | translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="pb-4 pe-5 ps-5 justify-content-between">
    <h3 class="self-align-left red">
        <span *ngIf="invalidFormName">
            {{error}}
        </span>
    </h3>
    <button mat-flat-button (click)="apply()" color="primary" [disabled]="!startDate || !endDate" class="export_apply-btn">{{'COM.BUTTONS.APPLY' | translate}}</button>
</mat-dialog-actions>
