import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suffixDate',
  standalone: true,
  pure: true
})
export class SuffixDatePipe implements PipeTransform {
  transform(day: number): string {
    if (day < 1 || day > 31) {
      return '';
    }

    let suffix = 'th';
    if (day % 10 === 1 && day % 100 !== 11) {
      suffix = 'st';
    } else if (day % 10 === 2 && day % 100 !== 12) {
      suffix = 'nd';
    } else if (day % 10 === 3 && day % 100 !== 13) {
      suffix = 'rd';
    }

    return `${day}${suffix}`;
  }
}
