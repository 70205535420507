import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDigitFormat',
  pure: true
})
export class TwoDigitFormatPipe implements PipeTransform {
  transform(value: number): string {
    
    if (isNaN(value)) {
      return '';
    }

    // Format the number to 2 digit string
    return value < 10 ? `0${value}` : `${value}`;
  }
}