import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dr-customer-offers-ui-reason-code-confirm',
  templateUrl: './reason-code-confirm.component.html',
  styleUrls: ['./reason-code-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReasonCodeConfirmComponent {

  protected postGateChangeReasons: string[];
  protected selectedReason!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { postGateChangeReasons: string[]}, public dialogRef: MatDialogRef<ReasonCodeConfirmComponent>, private translate: TranslateService) {
    this.postGateChangeReasons = data.postGateChangeReasons;
    console.log(data);
  }

  protected apply(): void {
    if (this.selectedReason) {
      this.dialogRef.close(this.selectedReason)
    } else {
      this.dialogRef.close();
    }
  }
  
  protected cancel():void{
    this.dialogRef.close();    
  }

}
