import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LibComponentsModule } from '@dr-customer-offers-ui/lib-components';
import { ENVIRONMENT } from '@dr-customer-offers-ui/lib-services';
import { MapTimePipe, ValidateNumberDirective } from '@ngx-interval-data-grid';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDateRangePickerModule } from 'ngx-date-range-picker';
import { NgxDropdownModule } from 'ngx-dropdown';
import { NgxGlobalContactFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';
import { AnalyticsService, NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { BulkOffersComponent } from './components/bulk-offers/bulk-offers.component';
import { BulkSelectionComponent } from './components/dialogs/bulk-selection/bulk-selection.component';
import { ExportReportComponent } from './components/dialogs/export/export-report.component';
import { ReasonCodeConfirmComponent } from './components/dialogs/reason-code-confirm/reason-code-confirm.component';
import { FiltersComponent } from './components/filters/filters.component';
import { GlobalAlertModule } from './components/global-alert/global-alert.module';
import { HeadboardComponent } from './components/headboard/headboard.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { TwoDigitFormatPipe } from './components/pipes/twoDigitFormat.pipe';
import { BaselinesTabTableComponent } from './components/tables/baselines-tab/baselines-tab-table.component';
import { ClearedTabTableComponent } from './components/tables/cleared-tab/cleared-tab-table.component';
import { DeclaredAvailabilityTabTableComponent } from './components/tables/da-tab/da-tab-table.component';
import { OpenTabTableComponent } from './components/tables/open-tab/open-tab-table.component';
import { ScheduleTabTableComponent } from './components/tables/schedule-tab/schedule-tab-table.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { IconsComponent } from './shared/icons/icons.component';
import { AppInterceptor } from './shared/interceptors/app.interceptor';
import { SuffixDatePipe } from './shared/pipes/suffix-date.pipe';
import { WeekDayPipe } from './shared/pipes/week-days.pipe';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

const materialModules = [
  MatButtonToggleModule,
  MatDividerModule,
  MatDialogModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatTabsModule,
  MatInputModule,
  MatCardModule,
  MatButtonModule,
  MatSidenavModule,
  MatListModule,
  MatIconModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatTableModule,
  MatSelectModule
];

@NgModule({
  declarations: [
    AppComponent,
    FiltersComponent,
    HeadboardComponent,
    TabsComponent,
    BulkOffersComponent,
    BulkSelectionComponent,
    ClearedTabTableComponent,
    OpenTabTableComponent,
    DeclaredAvailabilityTabTableComponent,
    ScheduleTabTableComponent,
    BaselinesTabTableComponent,
    ExportReportComponent,
    ReasonCodeConfirmComponent,
    TwoDigitFormatPipe
  ],
  imports: [
    NoDataComponent,
    BrowserModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    NgxGlobalContactFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
    GlobalAlertModule,
    FlexLayoutModule,
    LibComponentsModule,
    NgxDropdownModule,
    NgxDateRangePickerModule,
    ...materialModules,
    MapTimePipe,
    ValidateNumberDirective,
    SuffixDatePipe,
    WeekDayPipe,
    IconsComponent,
  ],
  providers: [
    TranslateService,
    HttpClient,
    AnalyticsService,
    { provide: NOC_ANALYTICS, useValue: analytics },
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
