import { Inject, Injectable } from '@angular/core';
import { Brands, DomainAwarenessService } from 'ngx-shared-services';
import { APIEndpoints, Environment } from "@dr-customer-offers-ui/lib-interfaces";
import { ENVIRONMENT } from '../app-config';

@Injectable({ providedIn: 'root' })
export class ApiEndpointsService implements APIEndpoints {

  private readonly endpoints!: APIEndpoints | undefined;

  constructor(
    private domainAwarenessService: DomainAwarenessService,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {
    const brand  = this.domainAwarenessService.brand as Brands;
    this.endpoints =
      this.environment.brands[brand] ||
      this.environment.brands[environment.defaults.brand];
  }

  get baseUrl(): string {
    return this.endpoints?.baseUrl || '';
  }

  get globalNavUrl(): string {
    return this.endpoints?.globalNavUrl || '';
  }
}
