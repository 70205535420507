import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TabOption } from '../../shared/models';


@Component({
  selector: 'dr-customer-offers-ui-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent implements OnChanges {
  @Output() tabChange = new EventEmitter<TabOption>();
  @Input() options!: TabOption[];
  protected selectedOption!: TabOption;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['options'].currentValue)  this.setSelectedTab();
  }

  protected setSelectedTab(): void {
    for (const option of this.options) {
      if (option.selected) {
        this.selectedOption = option;
        break;
      } else{
        this.selectedOption = this.options[0];
      }
    }
  }

  protected onTabChange(e: any): void {
    this.selectedOption = e.value;
    this.tabChange.emit(this.selectedOption);
  }
}
