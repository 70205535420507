import { Component, ViewChild } from '@angular/core';
import { NgxDateRangePickerOutput } from 'ngx-date-range-picker';
import { NgxDropdownComponent, NgxDropdownOption } from 'ngx-dropdown';
import { Observable, delay, tap } from 'rxjs';
import { FilterSelectionInterface } from '../../shared/models';
import { DataViewModelService } from '../../shared/services/data-vm.service';
import { InternalService } from '../../shared/services/internal.service';
import { MixPanelService } from '../../shared/services/mixpanel.service';

@Component({
  selector: 'dr-customer-offers-ui-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent {
  @ViewChild('ngx_program_dropdown', { static: true })
  ngxProgramDropdown!: NgxDropdownComponent;
  @ViewChild('ngx_reg_dropdown', { static: true })
  ngxRegDropdown!: NgxDropdownComponent;

  protected isProgramListLoaded = false;
  public registrationsData$!: Observable<NgxDropdownOption[] | null>;
  public selectedProgram$: Observable<NgxDropdownOption | null> = this.internalState.getSelectedProgram.pipe(
    delay(100), // add small delay so the ngxProgramDropdown gets initialized
    tap((program) => {
      if(program && this.ngxProgramDropdown)this.ngxProgramDropdown.changeSelectedOptions(program);
    })
    );
    public selectedreg$: Observable<NgxDropdownOption | null> = this.internalState.getSelectedReg.pipe(
    delay(100), // add small delay so the ngxRegDropdown gets initialized
    tap((reg) => {
      if (reg && this.ngxRegDropdown) this.ngxRegDropdown.changeSelectedOptions(reg);
    })
  );
  protected filterSelectedObj: FilterSelectionInterface = {
    programId: '',
    registrationId: '',
    dateRange: {} as NgxDateRangePickerOutput
  };
  private isFirstTime = true;
  public programsData$: Observable<NgxDropdownOption[] | null> = this.dataVMService.getProgramsList().pipe(
    tap((programs) => {
      if(programs === null || !programs.length) {
        if(this.isProgramListLoaded) {
          this.isProgramListLoaded = false;
        }
        this.getRegistrationsUsingSessionToken();
      } else {
        this.isProgramListLoaded = true;
      }
    })
  )

  programFirstTimeSelected = false;
  regFirstTimeSelected = false;
  periodSelected = false;

  constructor(
    private internalState: InternalService,
    private dataVMService: DataViewModelService,
    private mixPanelService: MixPanelService
  ) {}

  getRegsFromSelectedProgram(selectedOption: NgxDropdownOption[] | NgxDropdownOption): void {
    this.isFirstTime = true;
    if(Array.isArray(selectedOption)) return;
    this.filterSelectedObj = {...this.filterSelectedObj, programId: selectedOption.id};
    this.registrationsData$ = this.dataVMService.getRegistrationsList(this.filterSelectedObj.programId).pipe(
      tap((reg: NgxDropdownOption[] | null) => {
        if (!reg || reg.length === 0) this.filterSelectedObj = {...this.filterSelectedObj, registrationId: ''};
      })
    );
    
    // Do not trigger the mixpanel event for the first time.
    if(this.programFirstTimeSelected && selectedOption){
      this.mixPanelService.selectProgram(selectedOption.value);
      this.regFirstTimeSelected = false;
    }
    this.programFirstTimeSelected = true;
  }

  getRegistrationsUsingSessionToken(): void {
    this.registrationsData$ = this.dataVMService.getRegistrationsListUsingSession().pipe(
      tap((reg: NgxDropdownOption[] | null) => {
        if (!reg || reg.length === 0) this.filterSelectedObj = {...this.filterSelectedObj, registrationId: ''};
      })
    );
  }

  selectedRegistration(selectedOption: NgxDropdownOption[] | NgxDropdownOption): void {
    if(Array.isArray(selectedOption)) return;
    this.filterSelectedObj = {...this.filterSelectedObj, registrationId: selectedOption.id};
    if(this.isFirstTime) this.apply();
    this.isFirstTime = false;

    // Do not trigger the mixpanel event the first time or when the user manually changes the PROGRAM.
    if(this.regFirstTimeSelected && selectedOption){
      this.mixPanelService.selectRegistration(selectedOption.value);
    }
    this.regFirstTimeSelected = true;
  }

  weekPickerChange(output: NgxDateRangePickerOutput | Date): void {
    const dateRange = output as NgxDateRangePickerOutput;
    this.filterSelectedObj = {...this.filterSelectedObj, dateRange: (output as NgxDateRangePickerOutput)};

    // Called 2 times (one for start date and one for end date) --> only launch the event the 2nd time
    if(this.periodSelected && dateRange){
      this.mixPanelService.selectDateRange(`${dateRange.start?.format('MM/DD/YYYY')} - ${dateRange.end?.format('MM/DD/YYYY')}`);
      this.periodSelected = false;
    }else{
      this.periodSelected = true;
    }
  }

  protected apply(): void {
    // Exit "edit mode" when apply the filters
    this.internalState.editMode$.next(false);
    this.internalState.setFilterSelectionState({
      programId: this.filterSelectedObj.programId,
      registrationId: this.filterSelectedObj.registrationId,
      dateRange: this.filterSelectedObj.dateRange
    });
  }
}
