import * as moment from "moment-timezone";
import { BulkType, Week } from "ngx-interval-data-grid";

export type TabOptionValue = 'open' | 'cleared' | 'schedule' | 'baselines' | 'Declared availability';
export type PeriodOptionValue = 'Week' | 'Custom' | 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
export type HeaderButtpnType = 'Confirm' | 'Cancel';

export const HeaderButtonTypeEnum = {
  CONFIRM: 'Confirm' as HeaderButtpnType,
  CANCEL: 'Cancel' as HeaderButtpnType,
}

export type MinuteFrequencyOption = 15 | 30 | 60 | 1440;


export const PeriodOptionEnum = {
  Week: 'Week' as PeriodOptionValue,
  Custom: 'Custom' as PeriodOptionValue,
  Sunday: 'Sunday' as PeriodOptionValue,
  Monday: 'Monday' as PeriodOptionValue,
  Tuesday: 'Tuesday' as PeriodOptionValue,
  Wednesday: 'Wednesday' as PeriodOptionValue,
  Thursday: 'Thursday' as PeriodOptionValue,
  Friday: 'Friday' as PeriodOptionValue,
  Saturday: 'Saturday' as PeriodOptionValue
}


export const TabOptionsEnum = {
  Open: 'open' as TabOptionValue,
  Cleared: 'cleared' as TabOptionValue,
  Schedule: 'schedule' as TabOptionValue,
  Baselines: 'baselines' as TabOptionValue,
  Declared_Availability: 'Declared availability' as TabOptionValue
}

export interface TabOption {
  show: boolean,
  optOut: boolean,
  name: string,
  value: TabOptionValue,
  selected: boolean,
  descriptionTitle: string,
  descriptionText: string,
  showEditButton: boolean,
  bulkFunctionality: boolean,
  exportFunctionality: boolean
}

export interface PeriodOption {
  value: PeriodOptionValue,
  label: string,
  hidden: boolean
}

export interface ExportReportOutput {
  startMomentDate: moment.Moment | null | undefined,
  endMomentDate: moment.Moment | null | undefined,
}

export type InputType = 'Value' | 'Optout';

export const InputTypeEnum = {
  VALUE: 'Value' as InputType,
  OPTOUT: 'Optout' as InputType,
}

export interface BulkInput {
  bulkType: BulkType,
  inputType: InputType,
  value?: number,
  opt_out?: boolean,
  week?: Week,
  startDateAndTime?: moment.Moment | string,
  endDateAndTime?: moment.Moment | string
}

