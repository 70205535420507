import { Injectable } from '@angular/core';
import { OfferResponse } from '@dr-customer-offers-ui/lib-interfaces';
import { exportFile } from 'common-utils/dist/csv-export';
import * as moment from 'moment';
import { NgxDropdownOption } from 'ngx-dropdown';
import { ExportReportOutput } from '../models';

// TODO - export to conf or utils file
const headerTitles: ExportOffersModel = Object.freeze({
  registrationId: 'Registration ID',
  registrationName: 'Registration Name',
  date: 'Date',
  intervalStart: 'Interval Start',
  intervalEnd: 'Interval End',
  offerValue: 'Offer Value',
  optOut: 'Opt Out'
});

// TODO - export to models file
interface ExportOffersModel {
  registrationId: string;
  registrationName: string;
  date: string;
  intervalStart: string;
  intervalEnd: string;
  offerValue: string;
  optOut: string;
}

@Injectable({ providedIn: 'root' })
export class ExportCSVService {

  public exportCSV(dateRange: ExportReportOutput, timeZone: string | undefined, selectedRegistration: NgxDropdownOption, offer: OfferResponse[] | null | undefined): void {
    if (!offer || !timeZone) return;

    const filterStart = moment.tz(dateRange.startMomentDate?.format('YYYY-MM-DD HH:mm:ss'), timeZone);
    const filterEnd = moment.tz(dateRange.endMomentDate?.format('YYYY-MM-DD HH:mm:ss'), timeZone);

    const filteredData: OfferResponse[] = offer.filter((elem: OfferResponse) => {
        const start = moment.tz(elem.offer_start_dttm_utc, timeZone);
        const end = moment.tz(elem.offer_end_dttm_utc, timeZone);

        const isWithinRange = elem.offer_value && elem.offer_value['kW'] !== null && elem.offer_value['kW'] !== undefined && end.isSameOrBefore(filterEnd) && start.isSameOrAfter(filterStart);
        return isWithinRange;
    });

    const sortedData: OfferResponse[] = filteredData.sort((a, b) => moment(a.offer_start_dttm_utc).diff(moment(b.offer_start_dttm_utc)));

    if (sortedData && selectedRegistration) {
      const dataForCSV: ExportOffersModel[] = sortedData.map((elem: OfferResponse) => ({
        registrationId: selectedRegistration.id,
        registrationName: `"${selectedRegistration.value}"`,
        date: moment(elem.offer_start_dttm_utc).tz(timeZone).format('"ddd, DD MMMM YYYY"'),
        intervalStart: moment(elem.offer_start_dttm_utc).tz(timeZone).format('H:mm'),
        intervalEnd: moment(elem.offer_end_dttm_utc).tz(timeZone).format('H:mm'),
        offerValue: `${elem.offer_value['kW']}`,
        optOut: elem.offer_opt_out_state ? 'Yes' : 'No'
      }));
      exportFile(this.buildDataForCSV(dataForCSV), this.buildUniqueFileName());
    }
  }

  private buildDataForCSV(dataForCSV: ExportOffersModel[]): string {
    // Get the headers (property names)
    const headers: string[] = Object.keys(headerTitles).map((key) => headerTitles[key as keyof ExportOffersModel]);

    // Convert array of objects to CSV
    const csvArray: string[] = [
      headers.join(','), // Header row
      ...dataForCSV.map((obj) => Object.values(obj).join(',')) // Data rows
    ];

    return csvArray.join('\n');
  }

  private buildUniqueFileName(): string {
    const timestamp: number = Date.now();
    const randomId: string = Math.random().toString(36).substring(7);
    const fileName = `csv_export_offers_${timestamp}_${randomId}.csv`;
    return fileName;
  }
}
