import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DefaultState, UIState } from '../models';
import { DefaultIconSize, IconSize } from './icons-size.enum';
import { IconSizePipe } from './icons-size.pipe';
import { IconStatePipe } from './icons-state.pipe';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, IconSizePipe, IconStatePipe],
  selector: 'dr-customer-offers-ui-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconsComponent {
  @Input() name = '';
  @Input() state: UIState = DefaultState;
  @Input() size: IconSize = DefaultIconSize;
  @Input() isSVG = false;
  @Input() svgName = '';

}
