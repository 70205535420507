import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedRegistrationLabels, RegistrationResponse } from '@dr-customer-offers-ui/lib-interfaces';
import { APIData } from 'common-utils/dist/models/api';
import { Link } from 'common-utils/dist/models/pagination/link';
import { Observable, throwError, switchMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiEndpointsService } from '../api-endpoint/api-endpoints.service';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  constructor(
    private http: HttpClient,
    private apiService: ApiEndpointsService,
  ) {}

  getRegistrations(programId: string, links?: string): Observable<RegistrationResponse[]> {
    return this.getRegistrationsByProgramId(programId, links).pipe(
      switchMap((res: PaginatedRegistrationLabels) => {
        if (res.links) {
          return this.getRegistrations(programId, res.links).pipe(
            map((m) => {
              return [...res.registrations_labels, ...m];
            })
          );
        }
        return of(res.registrations_labels);
      })
    );
  }

  private getRegistrationsByProgramId(programId: string, pageLink?: string): Observable<PaginatedRegistrationLabels> {
    const { baseUrl } = this.apiService;
    let url = `${baseUrl}/registration-view-config?id=` + programId + `&pageSize=1000`;
    if (pageLink) {
      url = baseUrl.replace('v1', pageLink);
    }
    return this.http.get<APIData<RegistrationResponse[]>>(url, { withCredentials: true })
      .pipe(map((res) => {
        return this.extractPaginatedRegistrationsData(res);
      }),
      catchError(this.handleError),
    );
  }

  getRegistrationsUsingSession(links?: string): Observable<RegistrationResponse[]> {
    return this.getRegistrationsBySession(links).pipe(
      switchMap((res: PaginatedRegistrationLabels) => {
        if (res.links) {
          return this.getRegistrationsUsingSession(res.links).pipe(
            map((m) => {
              return [...res.registrations_labels, ...m];
            })
          );
        }
        return of(res.registrations_labels);
      })
    );
  }

  private getRegistrationsBySession(pageLink?: string): Observable<PaginatedRegistrationLabels> {
    const { baseUrl } = this.apiService;
    let url = `${baseUrl}/registration-view-config?pageSize=1000`;
    if (pageLink) {
      url = baseUrl.replace('v1', pageLink);
    }
    return this.http.get<APIData<RegistrationResponse[]>>(url, { withCredentials: true })
      .pipe(map((res) => {
        return this.extractPaginatedRegistrationsData(res);
      }),
      catchError(this.handleError),
    );
  }

  private extractPaginatedRegistrationsData(res: APIData<RegistrationResponse[]>): PaginatedRegistrationLabels {
    const links: Link | null = res.links !== undefined ? res.links : null;
    const paginatedRegistrations: PaginatedRegistrationLabels = {
      registrations_labels: this.extractRegistrationsData(res),
      links: links !== null ? links.nextPage : null
    };
    return paginatedRegistrations;
  }

  private extractRegistrationsData(res: APIData<RegistrationResponse[]>): RegistrationResponse[] {
    const payload = res.data;
    return payload as RegistrationResponse[];
  }

  private handleError(error: Error | HttpErrorResponse) {
    return throwError(() => error);
  }
}
