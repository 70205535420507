<div class="com-table" *ngIf="{ editMode: (editMode$ | async) } as vm">
  <div class="com-table_container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" [class.mat-mdc-table--spacing]="!vm.editMode" multiTemplateDataRows>
      <ng-container matColumnDef="time_period">
        <th mat-header-cell *matHeaderCellDef class="com-table_container--header-row">{{ 'COM.LABELS.TIME_FRAME' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="com-table_container--time-column">
          <b>{{ { inputTime: element.timePeriodKey, timeGap: (groupedData?.regConfig)!.intervalFrequency } | mapTime }}</b>
          ({{ timezoneAbbr }})
        </td>
      </ng-container>

      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef class="com-table_container--header-row">
          <ng-container> {{ column | weekDay }} {{ dates[i] | suffixDate }} </ng-container>
        </th>
        <td mat-cell *matCellDef="let element" class="com-table_container--columns">
          <div [class.com-table_container--columns-readonly]="!vm.editMode" [class.com-table_container--columns-readonly-last]="i === 6">
            <!-- Readonly mode -->
            <ng-container *ngIf="!vm.editMode; else editModeRef">
              <div class="td-cell_readonly">
                <div class="td-cell_readonly-container" [class.td-cell_readonly-container--color]="element[columns[i]].gate_closed">
                  <span class="td-cell_readonly-body">
                    <dr-customer-offers-ui-icons
                      *ngIf="element[columns[i]].gate_closed"
                      [state]="UIState.GateClosed"
                      name="lock_outlined"
                    ></dr-customer-offers-ui-icons>
                  </span>
                  <span class="td-cell_readonly-body" [class.td-cell_readonly-body--color]="element[columns[i]].gate_closed">{{
                    element[columns[i]].value
                  }}</span>
                </div>
              </div>
            </ng-container>
            <!-- /Readonly mode -->
            <!-- Edit Mode -->
            <ng-template #editModeRef>
              <ng-container *ngIf="element[columns[i]].gate_closed">
                <div class="td-cell--align">
                  <div class="td-cell_readonly">
                    <div class="td-cell_readonly-container" [class.td-cell_readonly-container--color]="element[columns[i]].gate_closed">
                      <span class="td-cell_readonly-body">
                        <dr-customer-offers-ui-icons
                          *ngIf="element[columns[i]].gate_closed"
                          [state]="UIState.GateClosed"
                          name="lock_outlined"
                        ></dr-customer-offers-ui-icons>
                      </span>
                      <span class="td-cell_readonly-body" [class.td-cell_readonly-body--color]="element[columns[i]].gate_closed">{{
                        element[columns[i]].value
                      }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="!element[columns[i]].gate_closed">
                <div class="td-cell--align">
                  <div class="td-cell">
                    <div class="td-cell_custom-input">
                      <input
                        class="td-cell_input"
                        type="number"
                        [value]="element[columns[i]].value"
                        (keyup)="inputToCellValue(element, $event, column)"
                      />
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-template>
            <!-- /Edit Mode -->
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail"
        ><td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div [class.table-element-empty]="vm.editMode">
            <!-- To maintain spacing between rows on edit mode -->
          </div>
        </td></ng-container
      >

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-element-row" [class.table-expanded-row]="vm.editMode"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    </table>
  </div>

  <div class="com-table_footer">
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons name="lock_outlined"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.GATE_CLOSED' | translate }}</span>
    </div>
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons name="logout_outlined"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.OPT_OUT' | translate }}</span>
    </div>
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons [isSVG]="true" svgName="beach_access.svg"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.HOLIDAY' | translate }}</span>
    </div>
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons [isSVG]="true" svgName="warning.svg"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.EDIT_INSIDE_GC' | translate }}</span>
    </div>
    <div class="com-table_footer-icon">
      <dr-customer-offers-ui-icons [isSVG]="true" svgName="sunny.svg"></dr-customer-offers-ui-icons>
      <span>{{ 'COM.LEGEND.DAYLIGHT_SAVINGS' | translate }}</span>
    </div>
  </div>
</div>
