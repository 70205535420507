import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Week } from "ngx-interval-data-grid";

@Pipe({
  name: 'weekDay',
  standalone: true,
  pure: true
})
export class WeekDayPipe implements PipeTransform {
  private weekEnumKeyMap: { [value: string]: string } = {};

  constructor(private translate: TranslateService) {
    Object.keys(Week).forEach(key => {
      const value = Week[key as keyof typeof Week];
      this.weekEnumKeyMap[value] = key;
    });
  }

  transform(value: string): string {
    return this.translate.instant('COM.DAYS.'+this.weekEnumKeyMap[value]) || value;
  }
}
