import { Pipe, PipeTransform } from "@angular/core";
import { DefaultState, UIState } from "../models";

@Pipe({
  standalone: true,
  name: 'iconState',
  pure: true
})
export class IconStatePipe implements PipeTransform {
  transform(value: UIState): string {
    return this.getIconState(value);
  }

  getIconState(state: UIState): string {
    state = state || DefaultState;
    switch(state) {
      case UIState.Primary:
        return 'com-icon--primary';
      case UIState.DaylightSavings:
        return 'com-icon--dayLightSavings';
      case UIState.EmergencyGate:
        return 'com-icon--emergencyGate';
      case UIState.ForcastedCleared:
        return 'com-icon--forcastedCleared';
      case UIState.GateClosed:
        return 'com-icon--gateClosed';
      case UIState.Holidays:
        return 'com-icon--holidays';
      case UIState.OptOut:
        return 'com-icon--optOut';
      case UIState.Default:
        return 'com-icon--default';
      default: return '';
    }
  }
}
