import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { NgxDateRangePickerOutput } from 'ngx-date-range-picker';
import { DaterangeISO, SelectedDateRange } from '../models/daterange';

@Injectable({ providedIn: 'root' })
export class DateService {
  getISODate(dateRange: NgxDateRangePickerOutput, timezone: string): DaterangeISO {
    const formattedStartDate = moment.tz(dateRange.start?.format('YYYY-MM-DD HH:mm:ss'), timezone);
    const formattedEndDate = moment.tz(dateRange.end?.format('YYYY-MM-DD HH:mm:ss'), timezone);

    const startDate = formattedStartDate.startOf('day');
    const endDate = formattedEndDate.endOf('day');

    const startOfWeek = startDate.clone().startOf('week').toISOString();
    const endOfWeek = endDate.clone().endOf('week').toISOString();

    return {
        start: startOfWeek,
        end: endOfWeek
    };
}


    getDaysBetweenDates(dateRange: SelectedDateRange) {
        const days: number[] = [];
        for (let m = dateRange.start; m.isBefore(dateRange.end); m.add(1, 'days')) {
            days.push(m.date());
        }
        return days;
    }
}
